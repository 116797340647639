/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateWatchwithToonsMessages = /* GraphQL */ `
  subscription OnCreateWatchwithToonsMessages {
    onCreateWatchwithToonsMessages {
      id
      name
      command
    }
  }
`;
export const onUpdateWatchwithToonsMessages = /* GraphQL */ `
  subscription OnUpdateWatchwithToonsMessages {
    onUpdateWatchwithToonsMessages {
      id
      name
      command
    }
  }
`;
export const onDeleteWatchwithToonsMessages = /* GraphQL */ `
  subscription OnDeleteWatchwithToonsMessages {
    onDeleteWatchwithToonsMessages {
      id
      name
      command
    }
  }
`;
