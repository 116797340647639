/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_content_delivery_bucket": "watchwithtoons-20200423093437-hostingbucket-production",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://d3hii3iqtifg0f.cloudfront.net",
    "aws_cognito_identity_pool_id": "eu-west-1:9bb2f92b-6fa3-412c-b1fb-ccdda170c77c",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_lP82MNYI8",
    "aws_user_pools_web_client_id": "3r7fl3nm4m13sblq9klf5vb88a",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://fipmybwfgbf7jlcruuj36xif6y.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_user_files_s3_bucket": "watchwithtoonss3",
    "aws_user_files_s3_bucket_region": "af-south-1"
};


export default awsmobile;
