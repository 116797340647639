/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createWatchwithToonsMessages = /* GraphQL */ `
  mutation CreateWatchwithToonsMessages(
    $input: CreateWatchwithToonsMessagesInput!
    $condition: ModelWatchwithToonsMessagesConditionInput
  ) {
    createWatchwithToonsMessages(input: $input, condition: $condition) {
      id
      name
      command
    }
  }
`;
export const updateWatchwithToonsMessages = /* GraphQL */ `
  mutation UpdateWatchwithToonsMessages(
    $input: UpdateWatchwithToonsMessagesInput!
    $condition: ModelWatchwithToonsMessagesConditionInput
  ) {
    updateWatchwithToonsMessages(input: $input, condition: $condition) {
      id
      name
      command
    }
  }
`;
export const deleteWatchwithToonsMessages = /* GraphQL */ `
  mutation DeleteWatchwithToonsMessages(
    $input: DeleteWatchwithToonsMessagesInput!
    $condition: ModelWatchwithToonsMessagesConditionInput
  ) {
    deleteWatchwithToonsMessages(input: $input, condition: $condition) {
      id
      name
      command
    }
  }
`;
